import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Oidc from "oidc-client";
import uuid4 from "uuid/v4";
import axios from "axios";
import queryString from "query-string";

const config = window.stacc_env || {};

class App extends Component {
  render() {
    return (
      <div style={{ height: "100vh", width: "100%" }} className="App">
        <Router style={{ height: "100%", width: "100%" }}>
          <div style={{ height: "100%", width: "100%" }}>
            <Route exact path="/" component={FrontPage} />
            <Route exact path="/callback" component={LoginCallbackPage} />
          </div>
        </Router>
      </div>
    );
  }
}

class FrontPage extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
    challengeIdentity();
  }

  render() {
    return <div />;
  }
}

const challengeIdentity = () => {
  const correlationState = uuid4().replace(/-/g, "");
  const { state } = queryString.parse(window.location.search);
  const postBody = { identityServerState: state, correlationState };
  axios
    .post("/api/initiate", postBody)
    .then(d => {
      new Oidc.UserManager(config.oidcConfig)
        .signinRedirect({
          ...config.oidcConfig,
          data: correlationState,
          extraQueryParams: config.extraQueryParams || null
        })
        .then(() => {})
        .catch(function (e) {
          console.error(e);
        });
    })
    .catch(err => {
      console.log("Error: ", err.message);
    });
};

const LoginCallbackPage = () => {
  new Oidc.UserManager(config.oidcConfig)
    .signinRedirectCallback()
    .then(user => {
      const postBody = { correlationState: user.state, idToken: user.id_token, accessToken: user.access_token };
      axios
        .post("/api/login", postBody)
        .then(({ data }) => {
          window.location.replace(`${config.initialAuthority}${config.internalAuthCallback}?${queryString.stringify({ state: data.state })}`);
        })
        .catch(err => {
          console.log("Error:", err);
        });
    })
    .catch(function (e) {
      console.error(e);
    });
  return <div />;
};

export default App;
